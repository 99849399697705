<template>
  <div class="quoteCard">
    <div v-if="img" class="quoteImg">
      <img :src="getImgUrl(img)" :alt="quotee" />
    </div>
    <div class="quote">
      <q>
        <slot></slot>
      </q>
    </div>
    <!--END quote -->
    <div v-if="quotee" class="quotee">{{ quotee }}</div>
    <div v-if="link" class="quoteLink">
      <span v-if="socialNetwork === 'twitch'" class="icon-twitch"></span>
      <span v-if="socialNetwork === 'twitter'" class="icon-twitter"></span>
      <span v-if="socialNetwork === 'facebook'" class="icon-facebook"></span>
      <span v-if="socialNetwork === 'youtube'" class="icon-youtube-play"></span>
      <a target="_blank" :href="link">{{ link }}</a>
    </div>
    <!-- END quoteLink -->
  </div>
  <!-- END productCard -->
</template>

<script>
import { IMAGES_URL } from "@/constants/";
export default {
  name: "QuoteCard",
  props: ["quotee", "socialNetwork", "img", "link"],
  methods: {
    getImgUrl(pic) {
      return IMAGES_URL + pic;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_variables.scss";

.quoteCard {
  display: grid;
  grid-template-columns: 60px 1fr;
  align-content: start;

  .quoteImg {
    grid-column: 1;
    grid-row: 1;

    img {
      width: 40px;
      height: 40px;
    }
  }
  .quote {
    grid-column: 2;
    grid-row: 1;
  }
  .quotee {
    grid-column: 2;
    grid-row: 2;
    font-weight: 700;
    color: #ffffff;
    margin-top: 12px;
  }
  .quoteLink {
    grid-column: 2;
    grid-row: 3;

    a {
      text-decoration: underline;
      color: #ffffff;
    }
  }
  .socialImg {
    width: 16px;
    height: auto;
    margin-right: 4px;
    position: relative;
    top: 2px;
  }
}
</style>
