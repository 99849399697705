<template>
  <div class="twoByTwo">
    <h1 v-if="title">{{ title }}</h1>
    <div class="contentWrapper">
      <slot></slot>
    </div>
    <!-- END content -->
  </div>
  <!-- END quoteGrid -->
</template>

<script>
export default {
  name: "TwoByTwo",
  props: ["title"]
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_variables.scss";

.twoByTwo {
  padding: 25px 0 65px;
}
h1 {
  text-align: center;
  font-size: 45px;
}
.contentWrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 50px;

  @include for-medium-up {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }


}
</style>
