<template>
  <div class="featuredGames">
    <div class="contentWrapper">
      <h1>Games Featuring TrackIR</h1>
    </div>
    <div class="games-list">
      <router-link to="/games/">
        <div class="game-background"></div>
      </router-link>
    </div>
    <!-- END games-list -->
    <div class="contentWrapper buttonWrapper">
      <router-link to="/games/">View All Games</router-link>
    </div>
    <!-- END content -->
  </div>
  <!-- END threeWide -->
</template>

<script>
export default {
  name: "featuredGames",
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_variables.scss";

.featuredGames {
  padding: 20px 0 80px;
  background: var(--color-bg-dark);
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.35);
}
h1 {
  text-align: center;
  font-size: 45px;
}
.buttonWrapper {
  text-align: center;

  a {
    @include btn();
     border: 1px solid #fff;
      &:hover {
          background: rgba(255, 255, 255, 0.1);
      }

  }
}
.game-background {
  background-image: url($images-url + "trackir-enhanced-games-vertical-v2.png");
  height: 400px;
  background-size: auto auto;
  background-position: 50%;
  background-repeat: no-repeat;
  margin-bottom: 60px;

  @include for-medium-up {
    background-image: url($images-url + "trackir-enhanced-games-horizontal-v4.png");
    height: 210px;
  }
}
</style>
