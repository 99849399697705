<template>
  <div class="home">
    <Monitor v-if="saleOccuring" :glitchMessage=saleOccuring />
    <Monitor v-if="!saleOccuring" />

    <div class="darkBG contentSpacing">
        <div class="contentWrapper">
          <product-details
            id="bundleDetails"
            class="bundle"
            theme="dark"
            :imgRight="true"
            label="Pro Bundle"
            img="pro-bundle@2x.png"
            promo="trackir5pro"
            coupon="NPK544CV640EK324CC01"
            v-if="!saleOccuring"
          >
          <p>Provides the option to use wireless tracking or more precise wired tracking.</p>
          <p>Includes:</p>
          <ul>
            <li>TrackIR 5 camera</li>
            <li>TrackIR camera base</li>
            <li>TrackClip for wireless tracking</li>
            <li>TrackClip Pro for more precise tracking</li>  
          </ul>
          </product-details>

          <product-details
            id="bundleDetails"
            class="bundle"
            theme="dark"
            :imgRight="true"
            :label="saleOccuring"
            img="pro-bundle@2x.png"
            promo="trackir5problackfriday2024"
            coupon="NPV847HA543KC627HA01"
            v-if="saleOccuring"
          >
          <p>Provides the option to use wireless tracking or more precise wired tracking.</p>
          <p>Includes:</p>
          <ul>
            <li>TrackIR 5 camera</li>
            <li>TrackIR camera base</li>
            <li>TrackClip for wireless tracking</li>
            <li>TrackClip Pro for more precise tracking</li>  
          </ul>
          </product-details>


        </div>
    </div>

    <two-wide className="gap-medium">
      <product-card
        id="tir5Card"
        label="TrackIR 5"
        img="spotlight-trackir-5@2x.png"
        theme="box"
        :itemId="268"
      >
      <p>Includes:</p>
      <ul>
        <li>TrackIR 5 camera</li>
        <li>TrackIR camera base</li>
        <li>TrackClip for wireless tracking</li>
      </ul>
      </product-card>
      <product-card
        id="tcpCard"
        label="TrackClip Pro"
        img="trackclip-pro@2x.png"
        theme="box"
        :itemId="139"
      >
      <p>Increases the accuracy of the TrackIR 5 camera by pairing it with a wired TrackClip Pro.</p>
      </product-card>
    </two-wide>

    <two-by-two title="What Gamers Are Saying">
      <quote-card
        img="logo-davecam.png"
        quotee="Dave Cam"
        socialNetwork="youtube"
        link="https://youtube.com/@davecamyt"
      >If you want all the benefits of Virtual Reality without the downsides, TrackIR would be perfect.
      </quote-card>
      <quote-card
        img="logo-baron@2x.png"
        quotee="BadNewsBaron"
        socialNetwork="twitch"
        link="https://twitch.tv/badnewsbaron"
      >It's a massive competitive edge in flight and space sims. It changes the
        game. There's no going back. It's that important.
      </quote-card>
      <quote-card
        img="logo-squirrel@2x.png"
        quotee="Squirrel"
        socialNetwork="youtube"
        link="https://youtube.com/squirrel"
      >As a professional broadcaster and big simulator fan, TrackIR forms an
        essential part of my immersive gaming experience.
      </quote-card>
      <quote-card
        img="logo-knowledge1970.png"
        quotee="Knowledge1970"
        socialNetwork="twitch"
        link="https://twitch.tv/knowledge1970"
      >No doubt about it, TrackIR is a significant advantage in combat, and
        frankly a far more immersive experience. I can't play my games without
        it anymore.
      </quote-card>
    </two-by-two>

    <featured-games></featured-games>
  </div>
</template>

<script>
// @ is an alias to /src
import Monitor from "@/components/Monitor.vue";
import TwoWide from "@/components/Layouts/TwoWide.vue";
import TwoByTwo from "@/components/Layouts/TwoByTwo.vue";
import ProductCard from "@/components/ProductCard.vue";
import ProductDetails from "@/components/ProductDetails.vue";
import QuoteCard from "@/components/QuoteCard.vue";
import FeaturedGames from "@/components/FeaturedGames.vue";
import mixin from "@/mixin";

export default {
  name: "home",
  mixins: [mixin],
  components: {
    Monitor,
    TwoWide,
    TwoByTwo,
    ProductCard,
    ProductDetails,
    QuoteCard,
    FeaturedGames
  },
};
</script>
<style lang="scss">

</style>
